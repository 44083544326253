import React from 'react'

import { Content } from '../../components/Content'
import { Playground } from '../../components/Playground'

const Page = () => (
  <Content
    heading="Embed"
    subHeading="Verkkopalvelu"
  >
    <Playground format="html">
      <div className="embed-responsive embed-responsive-16by9">
        <iframe
          allowFullScreen
          className="embed-responsive-item"
          src="https://www.youtube.com/embed/iMljeqa9kKs"
          title="Anna Työmarkkinatorin Hakupalvelun ehdottaa työpaikkaa"
        />
      </div>
    </Playground>
  </Content>
)

export default Page
